import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLink } from "../../services/linkService";

function RedirectToLink() {
    const [noLink, setNoLink] = useState(false)
    const { code } = useParams();
    const domain = window.location.hostname;

    useEffect(() => {
        if (code) {
            fetchLinkData({ code: code, domain: domain });
        }
    }, [code])

    const fetchLinkData = async (data) => {
        const res = await getLink(data);
        if (res.data) {
            window.location.replace(res.data.origin);
        }
        else {
            setNoLink(true);
        }
    }
    return (
        <>
            {
                noLink ? (
                    <div className="col-lg-7 m-lg-auto">
                        <img className="w-100" src="/images/404.gif" alt="404" />
                    </div>
                ) : (<></>)
            }
        </>
    );
}

export default RedirectToLink;
