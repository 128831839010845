import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { insertLink } from '../../services/linkService';

function Home() {
    const [shortLink, setShortLink] = useState();
    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            const domain = window.location.hostname;
            const origin = event.target.origin.value;
            const length = event.target.length.value;
            if (!origin) {
                return toast.error('Hãy nhập đường link gốc!');
            }
            if (!isValidUrl(origin)) {
                return toast.error('Sai định dạng đường link gốc!');
            }
            const data = {
                domain: domain,
                origin: origin,
                length: length
            }
            const res = await insertLink(data);
            if (res.data.status === 200) {
                setShortLink(`https://${domain}/${res.data.data.code}`)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const copy = () => {
        navigator.clipboard.writeText(shortLink);
        toast.success('Copy thành công!')
    }

    return (
        <>
            <div className="container">
                <div className='col-lg-3 col-6 m-auto mt-4 text-center'>
                    <img className='w-100' src="/logo.png" alt="logo" />
                </div>
                <h1 className='text-center fw-bold mt-4 mb-4'>ỨNG DỤNG TẠO LINK RÚT GỌN</h1>
                {
                    shortLink ? (
                        <p className='text-center fw-bold fs-5'>
                            <span className='fw-bold'>Link rút gọn của bạn là: </span>
                            <span className='text-success'>{shortLink}</span>
                            <button className='btn btn-light' onClick={copy}>copy</button>
                        </p>
                    ) : (<></>)
                }
                <form className='col-lg-5 m-lg-auto' action="post" onSubmit={onSubmit}>
                    <div>
                        <label className='fw-bold'>Đường link gốc:</label>
                        <input type="text" name='origin' className='form-control' placeholder='Ví dụ: https://google.com.vn' required />
                    </div>
                    <div className='mt-4'>
                        <label className='fw-bold'>Số lượng ký tự: (mặc định là 6 ký tự)</label>
                        <input type="number" min={6} max={24} name='length' className='form-control' placeholder='Nhập số lượng ký tự' />
                    </div>
                    <div className='text-center mt-4'>
                        <button className='btn btn-warning fw-bold'>TẠO LINK RÚT GỌN</button>
                    </div>
                </form>
            </div>
            <ToastContainer
                position="top-center"
            />
        </>
    );
}

export default Home;
